var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TabsNav',{attrs:{"strict":"","ignore-query-params":"","items":[
        {
            title: 'eav_entity.plural',
            route: {name: 'eav.entity', params: { slug: _vm.slug }},
            permissions: ['administrator', 'director'],
        },
        {
            title: 'eav_property.plural',
            route: {name: 'eav.type.property', params: { slug: _vm.slug }},
            permissions: ['administrator', 'director'],
        },
        {
            title: 'eav_entity.settings',
            route: {name: 'eav.type.edit', params: { slug: _vm.slug }},
            permissions: ['administrator', 'director'],
        }
    ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }