<script>
import TabsNav from "@/components/Leentech/TabsNav.vue";

export default {
    name: "EavMenu",
    components: {TabsNav},

    props: {
        slug: {
            type: String,
            required: true
        }
    }
}
</script>

<template>
    <TabsNav
        strict
        ignore-query-params
        :items="[
            {
                title: 'eav_entity.plural',
                route: {name: 'eav.entity', params: { slug: slug }},
                permissions: ['administrator', 'director'],
            },
            {
                title: 'eav_property.plural',
                route: {name: 'eav.type.property', params: { slug: slug }},
                permissions: ['administrator', 'director'],
            },
            {
                title: 'eav_entity.settings',
                route: {name: 'eav.type.edit', params: { slug: slug }},
                permissions: ['administrator', 'director'],
            }
        ]"
    />
</template>

<style scoped lang="scss">

</style>
