<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">

                <EavMenu :slug="slug" />
                <div class="d-flex align-center mb-4">
                    <h1>
                        {{ $t('eav_entity_type.settings_title') }}
                    </h1>
                </div>
                <v-card>
                    <v-card-text>
                        <v-form>
                            <ValidationObserver v-slot="{ invalid, validated, passes, validate }">
                                <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="slug">
                                    <TextInput
                                        id="slug"
                                        v-model="slug_value"
                                        :label="$t('eav.slug')"
                                        :error="!valid"
                                        :error-messages="errors"
                                    ></TextInput>
                                </ValidationProvider>

                                <ValidationProvider rules="" v-slot="{ errors, valid }" ref="display_property_id">
                                    <SelectInput
                                        :items="properties ?? []"
                                        id="display_property_id"
                                        v-model="display_property_id"
                                        item-text="name"
                                        item-value="id"
                                        :label="$t('eav.display_property_id')"
                                        :error="!valid"
                                        :error-messages="errors"
                                    ></SelectInput>
                                </ValidationProvider>

                                <template v-for="lng in listLanguages">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="name[lng]">
                                        <TextInput
                                            :id="`name_${lng}`"
                                            :label="$t(`name_${lng}`)"
                                            :error="!valid"
                                            :error-messages="errors"
                                            v-model="name[lng]"
                                            require
                                        ></TextInput>
                                    </ValidationProvider>
                                </template>

                                <v-checkbox v-model="forModel" :label="$t('eav.is_for_model')"/>
                                <template v-if="forModel">
                                    <TextInput
                                        id="model"
                                        :label="$t('eav.model')"
                                        :hint="$t('eav.model_desc')"
                                        v-model="model"
                                    ></TextInput>

                                    <TextInput
                                        :label="$t('eav.primary')"
                                        :hint="$t('eav.primary_desc')"
                                        id="primary"
                                        class="mb-4"
                                        v-model="primary"
                                    ></TextInput>
                                </template>
                            </ValidationObserver>
                            <Btn @click="saveItem">{{ $t('create') }}</Btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import SettingMenu from "../components/SettingMenu";
import TextInput from "../components/Form/TextInput";
import Btn from "@/components/Form/Btn.vue";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import SelectInput from "@/components/Form/SelectInput.vue";
import WithEntityProperties from "@/plugins/mixins/WithEntityProperties";
import EavMenu from "@/components/Eav/EavMenu.vue";

export default {
    name: "Pages",

    mixins: [
        WithEntityProperties
    ],

    directives: {
        mask,
    },

    components: {
        EavMenu,
        SelectInput,
        Btn,
        SettingMenu,
        TextInput,
        ValidationObserver,
        ValidationProvider
    },

    props: {
        slug: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            slug_value: null,
            display_property_id: null,
            properties: null,
            model: '',
            forModel: false,
            primary: '',
            name: {},
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['lang', 'listLanguages']),
        isCreating() {
            return this.slug === 'create';
        }
    },

    watch: {
        slug: {
            immediate: true,
            handler(value) {
                if(value !== 'create') {
                    this.fetchItem();
                    this.fetchEavEntityTypePropertyByEntitySlug(this.slug).then((properties) => {
                        this.properties = properties;
                    });
                }
            }
        },
    },

    mounted() {
    },

    methods: {
        fetchItem() {
            this.$http.get(`admin/eav/type/${this.slug}`)
                .then(response => {
                    let data = response.body.data;
                    this.model = data.model;
                    this.primary = data.primary;
                    this.forModel = data.model !== null;
                    this.slug_value = data.slug;
                    this.display_property_id = data.display_property?.id;
                    console.log(this.name);
                    this.listLanguages.forEach((lng) => {
                        this.name[lng] = data.translations[lng].name
                        this.$set(this, 'name', this.name);
                    })
                })
                .catch(errorResp => {
                    this.$toastr.error(errorResp);
                    this.$toastr.error(this.$t('eav_type.error'));
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        saveItem() {
            if(this.isCreating) {
                this.postItem();
            } else {
                this.putItem();
            }
        },

        postItem() {
            this.loading = true;
            let data = this.prepareData();

            this.$http.post('admin/eav/type', data)
                .then(response => {
                    this.$router.push({name: 'eav.type.edit', params: {slug: response.body.data.slug}});
                })
                .catch(errorResp => {
                    this.$toastr.error(this.$t('eav_type.error'));
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        putItem() {
            this.loading = true;
            let data = this.prepareData();

            this.$http.put('admin/eav/type/' + this.slug, data)
                .then(response => {
                    this.$toastr.success(this.$t('eav_type.updated'));
                })
                .catch(errorResp => {
                    this.$toastr.error(this.$t('eav_type.error'));
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        prepareData() {
            let data = {
                slug: this.slug_value,
                display_property_id: this.display_property_id
            };

            if(this.forModel) {
                data.model = this.model;
                data.primary = this.primary;
            }

            this.listLanguages.forEach((lng) => {
                if (!data[lng]) {
                    data[lng] = {};
                }
                data[lng]['name'] = this.name[lng];
            })

            return data;
        }
    }
}
</script>
